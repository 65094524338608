<template>
  <li>
    <item-card contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ user.name }}
        </h3>
      </template>
      <template v-slot:buttons>

        <Button icon="pencil" @click="editUser(user.id)" />

        <Button icon="delete" @click="deleteUser(user.id)" />

      </template>

      <template v-slot:content>
        
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
          <item-text title="Loma" :text="user.role.display_name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-6">
          <item-text title="E-pasts" :text="user.email" />
        </div>

      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "UserListItem",
  components: {
    ItemCard,
    ItemText,
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  methods: {
    editUser(userId){
      this.$router.push({ path: '/users/edit/' + userId })
    },
    deleteUser(userId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteUser', userId)
      }
    },
  }
}
</script>
