<template>
  <Modal :size="'md'" :showModal="showModal">
    <template v-slot:button>
      <div class="flex h-full items-center">
        <button
          @click="showModal = true"
          class="
            rounded-full
            w-12
            h-12
            bg-gray-200
            dark:bg-main4
            flex
            justify-center
            items-center
            leading-none
            hover:bg-gray-300
            transition
            duration-300
            ease-in-out
            dark:text-gray-100
          "
        >
          <PlusIcon class="w-7 h-7"/>
        </button>
      </div>
    </template>

    <template v-slot:content>
      <form class="space-y-6 w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <Input
              name="name"
              placeholder="Lietotāja vārds"
              v-model="form.name"
              :errors="errors.name"
            />
          </div>

          <div class="col-span-1">
            <Input
              name="email"
              placeholder="E-pasts"
              v-model="form.email"
              :errors="
                errors.email
                  ? errors.email
                  : errorMessages.email
                  ? errorMessages.email
                  : []
              "
            />
          </div>

            <div class="col-span-1">
                <Input
                    name="password"
                    placeholder="Parole"
                    v-model="form.password"
                    type="password"
                    :errors="
                errors.password
                  ? errors.password
                  : errorMessages.password
                  ? errorMessages.password
                  : []
              "
                />
            </div>

          <div class="col-span-1">
            <Select
              v-model="form.role"
              :errors="errors.role"
              :items="inputData.roles"
              name="display_name"
              placeholder="Loma"
            />
          </div>
        </div>

        <template v-if="form.role && form.role.id">
          <template v-if="form.role.permissions.length > 0">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <div class="col-span-3">
                <h2>Lomas atļaujas:</h2>
              </div>
              <template
                v-for="permission in form.role.permissions"
                :key="permission.id"
              >
                <div class="col-span-1">
                  <ItemText :title="permission.display_name" />
                </div>
              </template>
            </div>
          </template>

          <template v-if="roleAvailableAdditionalPermissions.length > 0">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <div class="col-span-3">
                <h2>Papildus atļaujas:</h2>
              </div>
              <template
                v-for="(
                  permission, index
                ) in roleAvailableAdditionalPermissions"
                :key="permission.id"
              >
                <div class="col-span-1">
                  <Checkbox
                    :text="permission.display_name"
                    :value="permission.id"
                    v-model="form.additionalPermissions[index]"
                  />
                </div>
              </template>
            </div>
          </template>
        </template>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
              @click="hideAddUserForm"
            >
              Atcelt
            </button>

            <template v-if="!loading">
              <button
                type="button"
                class="
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-gradient-to-r
                  from-button2-from
                  to-button2-to
                  hover:from-button2-to hover:to-button2-from
                  focus:outline-none focus:border-none
                  transition
                  duration-500
                  ease-in-out
                "
                @click.prevent="submit"
              >
                Pievienot
              </button>
            </template>
            <template v-else>
              <Loading />
            </template>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Components/Modal";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import ItemText from "@/components/Components/ItemText";
import Checkbox from "@/components/Components/Checkbox";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "AddUser",
  components: {
    Modal,
    Select,
    Input,
    Checkbox,
    ItemText,
    Loading,
    PlusIcon,
  },
  data: () => ({
    form: {
      additionalPermissions: [],
      name: "",
      email: "",
      role: "",
    },
    showModal: false,
  }),
  watch: {
    showModal() {
      this.form = {
        additionalPermissions: [],
        name: "",
        email: "",
        role: "",
        password: "",
      };
    },
  },
  mounted() {
    this.$store.dispatch("getUserInputData");
  },
  computed: {
    ...mapGetters({
      inputData: "userInputData",
      loading: "loading",
      errors: "errors",
      errorMessages: "userErrorMessages",
    }),
    roleAvailableAdditionalPermissions() {
      return this.inputData.all_permissions.filter((permission) => {
        return !this.form.role.permissions.some((rolePermission) => {
          return permission.id === rolePermission.id;
        });
      });
    },
    formValidation() {
      return {
        name: {
          rules: ["required"],
        },
        email: {
          rules: ["required", "email"],
        },
        role: {
          rules: ["required"],
        },
          password: {
              rules: ["required"],
          },
      };
    },
  },
  methods: {
    hideAddUserForm() {
      this.$store.dispatch("clearUserErrorMessages");
      this.$store.dispatch("removeAllFormsForDisplay");
      this.showModal = false;
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        const additionalPermissions = this.form.additionalPermissions.filter(
          (permission) => permission
        );
        this.$store
          .dispatch("createNewUser", {
            name: this.form.name,
            email: this.form.email,
            password: this.form.password,
            role_id: this.form.role.id,
            additional_permissions:
              this.form.additionalPermissions.length > 0
                ? additionalPermissions
                : null,
          })
          .then(() => {
            this.showModal = false;
          });
      } else {
        this.$Progress.fail();
      }
    },
  },
};
</script>